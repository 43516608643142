@import '../../App.sass'

.footer
    background: rgba(2, 51, 118, 0.03) 
    font-size: $fs - 2
    margin-top: $blh
    .container
        +container($container)
        padding: $blh * 3 $blh $blh * 2
        +media-screen('max', $sm)
            padding: $blh calc($blh/2)

    .company
        padding: 0 $blh
        +media-screen('max', $sm)
            width: 100%
            margin: $blh 0 0 0
            text-align: left
        &__title
            color: $clr-main
            font-weight: 900
            font-size: $fs - 3
            text-transform: uppercase
            margin-bottom: calc( $blh/2)
        &__link
            display: block
            margin: calc($blh/3) 0


    &__disclaimer
        font-size: $fs - 5
        line-height: normal
        margin-top: calc($blh/2)
        max-width: 350px
        +media-screen('max', $sm)
            max-width: 100%