$clr-main: #ebbe16
$clr-b: #191919
$clr-w: #e5e5e5
$clr-o: #dd9210 

$ff-main: 'Open Sans', Arial, Helvetica, sans-serif
$fs: 16px
$blh: 28px


//////////////////////////////////////////////
// Media Break Points & Query Mixin
$xxs: 376px
$xs: 576px
$sm: 768px
$md: 992px
$lg: 1200px
$xlg: 1400px
// Media Query: Screen by Width
=media-screen($type: false, $bp: false, $bp2: false)
  @if ($type == 'min') and ($bp != false)
    @media screen and (min-width: $bp)
      @content
  @else if ($type == 'max') and ($bp != false)
    @media screen and (max-width: ($bp - 1))
      @content
  @else if ($type == 'range') and ($bp != false) and ($bp2 != false)
    @media screen and (min-width: $bp) and (max-width: ($bp2 - 1))
      @content



$container: 1280px
=container($container)
  width: 100%
  max-width: $container
  margin: auto
  padding: $blh
  &:after
    clear: both
  +media-screen('max', $sm)
    padding: $blh calc($blh/2)


// layout --- Dynamic layout Structure to reuse 
.layout
  position: relative
  margin: calc($blh*1.5) 0
  +media-screen('max', $md)
    margin: $blh 0
  +media-screen('max', $sm)
    margin: calc($blh/2) 0
  &:after
      content: ''
      display: block
      clear: both
  &__col
    display: inline-block
    position: relative
    vertical-align: top
    padding: calc($blh/2)
    &--1
      width: 10%
    &--2
      width: 20%
    &--3
      width: 30%
    &--4
      width: 40%
    &--5
      width: 50%
    &--6
      width: 60%
    &--7
      width: 70%
    &--8
      width: 80%
    &--9
      width: 90%
    +media-screen('max', $sm)
      display: block
      width: 100%

// Grid --- Dynamic Grid Structure to reuse 
.grid
    position: relative
    white-space: nowrap
    &:after
        content: ''
        display: block
        clear: both      
    &__col
        position: relative
        white-space: normal
        .grid--space &
          padding: $blh
        .grid--centered &
          text-align: center
          img
            display: block
            margin: auto

    +media-screen('min', $sm)
        margin-left: -$blh
        margin-right: -$blh
        &__col
            display: inline-block
            vertical-align: top
            padding: 0 $blh
            @for $i from 2 through 12
                .grid--col#{$i} &
                    width: calc(100% / $i)
            &--right
                float: right

//Animation ---  animation-name, animation-duration, animation-timing-function, animation-delay, animation-iteration-count, animation-direction, animation-fill-mode, and animation-play-state.
=animation($anime)
  -moz-animation: $anime
  -webkit-animation: $anime
  animation: $anime

//Transition ---  property duration timing-function delay|initial|inherit;
=transition($transition: all .3s ease-out)
  transition: $transition
  -webkit-transition: $transition
  -moz-transition: $transition
  -ms-transition: $transition
  -o-transition: $transition

=transition-2($prop-a, $prop-b)
  transition: $prop-a, $prop-b
  -webkit-transition: $prop-a, $prop-b
  -moz-transition: $prop-a, $prop-b
  -ms-transition: $prop-a, $prop-b
  -o-transition: $prop-a, $prop-b

//Transform --- none|transform-functions|initial|inherit;
=transform($transform: none)
  transform: $transform
  -webkit-transition: $transform
  -moz-transition: $transform
  -ms-transition: $transform
  -o-transform: $transform

//Filter ---  none | blur() | brightness() | contrast() | drop-shadow() | grayscale() | hue-rotate() | invert() | opacity() | saturate() | sepia() | url();
=filter($filter: none)
  filter: $filter
  -ms-filter: $filter
  -webkit-filter: $filter

=user-select($user-select: none)
  user-select: $user-select
  -webkit-user-select: $user-select
  -moz-user-select: $user-select
  -ms-user-select: $user-select

//Border Radius --- num
=border-radius($border-radius: 5px)
  border-radius: $border-radius
  -webkit-border-radius: $border-radius
  -moz-border-radius: $border-radius
  -ms-border-radius: $border-radius

//Box Shadow --- none|h-offset v-offset blur spread color |inset|initial|inherit;
=box-shadow($boxshadow: 0 0 10px 10px rgba(0,0,0,0.7))
  box-shadow: $boxshadow
  -webkit-box-shadow: $boxshadow
  -moz-box-shadow: $boxshadow

//Text Shadow --- h-shadow v-shadow blur-radius color|none|initial|inherit;
=text-shadow($text-shadow: 0 0 5px rgba(0,0,0,0.3))
  -webkit-font-smoothing: antialiased
  text-shadow: $text-shadow
  -webkit-text-shadow: $text-shadow

  
@mixin bg-tint($tint_color, $image_url)
  background-image: linear-gradient( $tint_color, $tint_color ), url( $image_url )



*, 
*:before,
*:after
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  margin: 0
  padding: 0

input,
textarea
  font-family: $ff-main

body
  background: #fff
  color: lighten(#000, 0.9)
  display: block
  margin: 0
  font-size: $fs
  line-height: $blh
  font-family: $ff-main
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
a,
a:visited
  color: $clr-main
  text-decoration: none


code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.container
  +container($container)
  &--flex
    display: flex
    align-items: flex-start
    +media-screen('max', $sm)
        flex-wrap: wrap




.title,
h1
  font-size: $fs * 1.3
  line-height: normal
  color: $clr-b
  text-transform: uppercase

.btn,
a.btn,
a.btn:visited
  cursor: pointer
  color: #fff
  display: block
  border-radius: 5%
  background: $clr-o
  font-size: $fs
  font-weight: 600
  text-align: center
  padding: calc($blh/1.8) $blh
  margin: calc($blh/2) 0
  +transition()
  width: fit-content
  &:hover
    background: darken($clr-o, 10%)
  &.btn--alt
    background: $clr-b
    &:hover
    background: darken($clr-b, 10%)
    color: #fff
    
[role="button"]
  cursor: pointer
