@import '../../App.sass'

.publicworkshops
    .workshop
        background: darken(#fff, 5%)
        padding: calc($blh/2) $blh
        margin: $blh 0 
        &__title
            font-size: $fs * 2
            font-weight: 600
            line-height: normal
            text-align: left
            +media-screen('max', $sm)
                font-size: $fs * 1.3
            &--sub
                font-size: $fs * 1.3
                text-transform: uppercase
                text-align: center
                +media-screen('max', $sm)
                    font-size: $fs
        &__desc
            text-align: left
        &__btn 
            @extend .btn
            margin: 0 auto calc($blh/2)
            line-height: normal
            font-size: $fs * 1.3
        &__img
            display: block
            width: 100%
            max-width: 250px
            margin: auto
        &__unavaliable
            margin: $blh auto 0
        .list li
            list-style: none
            display: inline-block
            margin: calc($blh/2)
            +media-screen('max', $md)
                display: block
                margin: auto
        small
            font-size: $fs - 4
            line-height: normal
            display: block
            text-align: center
        .grid
            text-align: center