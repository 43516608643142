
@import '../../App.sass'

////////////////////////////////////////////////////////////////////
////// header
.sr-only
    display: none
.header

    .logo
        +container($container)
        display: block
        padding: 0
        color: $clr-b
        font-size: $fs * 3.5
        font-weight: 600
        text-transform: uppercase
        +media-screen('max', $md)
            font-size: $fs * 2.4
        +media-screen('max', $sm)
            font-size: $fs * 1.6
            padding: calc($blh/2)
        +media-screen('max', $xs)
            font-size: $fs 
            padding: calc($blh/2) 0
        img
            display: inline-block
            width: 100%
            max-width: 180px
            margin: calc($blh/2) 0 calc($blh/-2) 0
            vertical-align: text-bottom
            +media-screen('max', $md)
                max-width: 100px
            +media-screen('max', $sm)
                max-width: 80px
                margin: 0
                vertical-align: middle


    .nav
        display: block
        color: #fff
        margin: $blh 0
        background: $clr-main
        background: -moz-linear-gradient(top,$clr-main 0,$clr-o 100%)
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,$clr-main),color-stop(100%,$clr-o ))
        background: -webkit-linear-gradient(top,$clr-main 0,$clr-o  100%)
        background: -o-linear-gradient(top,$clr-main 0,$clr-o  100%)
        background: -ms-linear-gradient(top,$clr-main 0,$clr-o  100%)
        background: linear-gradient(to bottom,$clr-main 0,$clr-o  100%)
        border-top: 1px solid #FBF2D0
        +media-screen('max', $md)
            float: none
            display: block
            margin: auto 0 auto auto
        &__container
            +container($container)
            padding: 0
            +media-screen('max', $sm)
                padding: 5px
        a.active
            background: $clr-o
            text-decoration: none
            cursor: default
        &__noscroll
            overflow: hidden
        &__mobile
            transition: opacity 0.3s ease-out, top 0.3s ease-out, transform 0.3s ease-out
            display: none
            background: transparent
            border: solid 2px $clr-o
            border-radius: 5px
            color: #fff
            padding: 5px 10px
            margin: 17px 5px
            height: 45px
            width: 45px
            outline: none
            &:hover, &:focus, &:active
                outline: none
            &-icon
                height: 3px
                width: 25px
                background: $clr-o
                display: block
                margin: 5px -2px
                .nav__mobile--active &
                    transform: rotate(45deg)
                    position: absolute
                    top: calc( $blh/2 )
                    opacity: 0.8
                    &:last-child 
                        transform: rotate(-45deg)
                        position: absolute
                        top: calc( $blh/2 )
                        
                .nav__mobile--active &
                    opacity: 0.5
                    position: absolute
                    margin: -2px
                    transform: rotate(-135deg)
                    &:last-child
                        opacity: 1
                        transform: rotate(135deg)


        &__list
            list-style: none
            margin: 0
            padding-left: 0
            &-item
                color: #fff
                display: inline-block
                padding: 0
                position: relative
                text-decoration: none
                font-family:  $ff-main
                font-size: $fs
                text-transform: uppercase
                transition-duration: 0.5s
                a,
                a:visited
                    color: #fff
                    display: block
                    position: relative
                    text-decoration: none
                    padding: $blh calc($blh * 1.8)
                    background: $clr-main
                    background: -moz-linear-gradient(top,$clr-main 0,$clr-o 100%)
                    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,$clr-main),color-stop(100%,$clr-o ))
                    background: -webkit-linear-gradient(top,$clr-main 0,$clr-o  100%)
                    background: -o-linear-gradient(top,$clr-main 0,$clr-o  100%)
                    background: -ms-linear-gradient(top,$clr-main 0,$clr-o  100%)
                    background: linear-gradient(to bottom,$clr-main 0,$clr-o  100%)
                    border-top: 1px solid #FBF2D0
                    font-weight: bold
                    text-transform: uppercase
                    text-shadow: 0 1px #cc9611
                    border-left: 1px solid #d9850c
                    border-right: 1px solid #f3d71d
                    +media-screen('max', $lg)
                        padding: $blh
                    +media-screen('max', $md)
                        padding: calc($blh/1.2)
                    +media-screen('max', $sm)
                        border-left: none
                        border-right: none
                a:hover
                    background: $clr-o
                    color: #fff
                    cursor: pointer

        
                

        +media-screen('max', $md)
            &__list-item a
                font-size: $fs - 2
        +media-screen('max', $sm)
            float: none
            width: 100%
            clear: both
            display: block
            &__list
                display: none
                &--active
                    display: block
                    clear: both
                &-item
                    float: none
                    display: block
                    a
                        padding: calc($blh/3) $blh
                        font-size: $fs - 2
            &__mobile
                display: block
                position: absolute
                top: 0
                right: 0
                &--active
                    .nav__mobile-icon
                        transform: rotate(45deg)
                        position: absolute
                        top: calc( $blh/2 )
                        opacity: 0.8
                        +transition()
                        &:last-child 
                            transform: rotate(-45deg)
                            position: absolute
                            top: calc( $blh/2 )
         