@import '../../App.sass'

@keyframes slide-up 
  0% 
    visibility: visible
    left: 0
  
  100% 
    visibility: visible
    left: 5%
    
.slider 
    +container($container)
    height: 40vh
    min-height: 500px
    position: relative
    overflow: hidden
    background: $clr-b
    +media-screen('max', $sm)
        height: 70vh


    &__slide 
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 0.6
        transform: translateX(-5%)
        transition: all 0.5s ease
        &--current 
            opacity: 1
            transform: translateX(0)
    &__img
        display: block
        margin: auto
        height: 100%
        +media-screen('max', $md)
            position: absolute
            left: calc(50% - 640px)
    &__content 
        position: absolute
        bottom: 5%
        opacity: 0
        width: 50%
        color: #fff
        padding: $blh
        background: rgba(0, 0, 0, 0.7)
        animation: slide-up 1s ease .2s
        animation-fill-mode: forwards
        visibility: hidden
        +media-screen('max', $sm)
            width: 90%
        .slider__slide--current &
            opacity: 1
            transform: translateX(0)
            transition: all 0.5s ease





    &__controls 
        cursor: pointer
        height: $blh
        width: $blh
        position: absolute
        z-index: 999


    &__next 
        top: 35%
        right: 2%
        width: 0 
        height: 0
        border-top: $blh solid transparent
        border-bottom: $blh solid transparent
        border-left: $blh solid $clr-o
        opacity: 0.6
        &:hover 
            opacity: 1
    &__prev 
        top: 35%
        left: 2%
        width: 0 
        height: 0
        border-top: $blh solid transparent
        border-bottom: $blh solid transparent
        border-right: $blh solid $clr-o
        opacity: 0.6
        &:hover 
            opacity: 1

