@import '../../App.sass'

.home
    .header .nav
        margin-bottom: 0
    .slideshow
        margin: 0 auto $blh
        max-width: 1280px
        position: relative
        &__title
            background: rgba(25, 25, 25, 0.7)
            color: #fff
            padding: $blh
            width: 65%
            position: absolute
            left: $blh
            bottom: $blh
            font-weight: 900
            text-transform: capitalize
            line-height: normal
            text-align: center
        &__img
            display: block
            width: 100%
    .text__inline
        display: inline-block
        width: 75%
    .img__inline
        display: inline-block
        max-width: 200px
        width: 20%
        margin: calc($blh/2) 0
        vertical-align: middle

    .services
        .container
            +container($container)
        &__container
            display: flex
            align-items: stretch 
            justify-content: center
            +media-screen('max', $md)
                flex-wrap: wrap
        
        &__title
            color: $clr-main
            text-align: center
            text-transform: uppercase
            font-size: $fs * 1.4
            font-weight: 600
            line-height: normal
            margin: 90px 0 0
        a p
            color: $clr-b
        &__item
            display: inline-block
            margin: 1%
            border: solid 1px $clr-main
            border-radius: 5%
            padding: $blh calc($blh/2) calc($blh*2)
            position: relative
            text-align: center
            transition: .5s
            background-size: 75px auto
            background-position: center 20px
            background-repeat: no-repeat
            width: 25%
            &::after
                content: 'Learn More'
                background: $clr-o
                width: 60%
                padding: calc($blh/5)
                +border-radius(10px 10px 0 0)
                color: #fff
                font-weight: 600
                text-transform: uppercase
                position: absolute
                bottom: 0
                left: 20%
                +transition()
            &:hover
                background-color: lighten($clr-o, 50%)
                border-color: $clr-o
                &::after
                    background: darken($clr-o, 5%)
            &--assessments
                background-image: url("../../assets/images/home/assessments.png")
            &--restructuring
                background-image: url("../../assets/images/home/restructuring.png")
            &--interim
                background-image: url("../../assets/images/home/interim.png")
            &--performance
                background-image: url("../../assets/images/home/performance.png")
            +media-screen('max', $md)
                width: 45%
            +media-screen('max', $sm)
                width: 90%
                margin: 3%
            


    a.services__item,
    a:visited.services__item
        color: $clr-main

    .grid__img
        max-width: 280px
