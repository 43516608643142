@import '../../App.sass'

.contact
    &__container
        display: flex
        flex-wrap: wrap
        margin: $blh 0
        align-items: stretch
        justify-content: space-between
        +media-screen('max', $sm) 
            flex-direction: column-reverse
    &__info
        max-width: 35%
        padding: $blh
        +media-screen('max', $sm)
            max-width: 100%
    &__link
        display: block
        margin: calc($blh/3) 0
        &--facebook
            background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg height='100%25' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;' version='1.1' viewBox='0 0 512 512' width='100%25' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:serif='http://www.serif.com/' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z'/%3E%3C/svg%3E") center center/30px no-repeat
            display: block
            width: 30px
            height: 30px
            cursor: pointer
            overflow: hidden
            text-indent: -999px
.form
    display: flex
    flex-wrap: wrap
    align-items: stretch
    justify-content: space-evenly 
    max-width: 65%
    +media-screen('max', $sm)
        max-width: 100%
    &__label
        width: 50%
        flex-grow: 2
        flex-shrink: 1
        font-size: calc($fs /1.5)
        text-transform: uppercase
        &-message
            width: 100%
    &__input
        display: block
        padding: calc($blh/1.5)
        border: solid 1px $clr-w
        font-size: $fs
        width: 100%
    &__submit
        display: block
        border: none
        text-transform: uppercase
        @extend .btn

