@import '../../App.sass'

.ourclients
    .list li
        list-style: none 
        margin: 0 0 calc($blh/2)

    .title
        &--sub
            font-weight: 600
            font-size: $fs * 1.5
            margin: $blh 0 0
        &--sm
            color: $clr-o
            text-transform: uppercase
            font-weight: 400
            font-size: $fs * 1.2